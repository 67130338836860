import { ContactDescription } from '../Types/AuthT';
import { ContactsFilter } from '../Types/Contact';
import { DistanceDto } from '../Types/LocationT';
import { PageableResponse } from '../Types/PageableResponse';
import { RequestAnswer } from '../Types/RecommendationT';
import { ajaxActions } from './AjaxActions';
import { EventS } from './EventS';
import { ToastS } from './ToastS';

type ContactType = 'PRIVATE' | 'COMPANY';

export type Contact = {
  id: number;
  applicationUserId: number;
  salutation?: string;
  firstName: string;
  lastName: string;
  companyName: string;
  email: string | null;
  addressStreetAndNo?: string;
  zipCode?: string;
  city?: string;
  phoneNumber?: string;
  phoneNumberAlt?: string;
  contactType: ContactType;
  lastModified: Date;
  groups: string[];
  salutationOfficial?: string;
  address?: string;
  personDescription: string;
  value?: string;
  distance?: DistanceDto | null; // God forbid me for this solution
  requestAnswer?: RequestAnswer;
  partnerId?: number | null;
  partner?: Contact;
};

export interface ContactsColumnVisibility {
  showMail: boolean;
  showPhone: boolean;
  showGroups: boolean;
}

export type ContactRelation = {
  id: number;
  description: string;
  relatedContactId: number;
};

export type ContactPersonDescription = Pick<Contact, 'personDescription'>;

const ContactSkeleton = {
  contactType: 'PRIVATE',
};

const BASE_URL = process.env.REACT_APP_AUTH_SERVICE_URL;

const fetchAll = async (): Promise<Contact[]> => {
  const res = await ajaxActions.get(`${BASE_URL}/contacts`).then((resp) => resp);
  if (res.ok) {
    return res.json();
  }
  ToastS.generalError();
  return [];
};

const fetchAllWithFilter = async (
  filter: ContactsFilter,
  abortSignal: AbortSignal,
): Promise<PageableResponse<Contact> | null> => {
  const { query, pageNumber, groupOfInterests } = filter;
  let targetUrl = `${BASE_URL}/contacts/all?pageNumber=${pageNumber}`;
  if (query) {
    targetUrl += `&query=${query}`;
  }
  if (groupOfInterests) {
    targetUrl += `&groupOfInterest=${groupOfInterests}`;
  }
  const res = await ajaxActions.get(targetUrl, { signal: abortSignal });
  if (res.ok) {
    return res.json();
  }
  return null;
};

const fetchById = async (id: number, abortSignal?: AbortSignal): Promise<Contact | null> => {
  const res = await ajaxActions.get(`${BASE_URL}/contacts/${id}`, { signal: abortSignal });
  if (res.ok) {
    return res.json();
  }
  ToastS.generalError();
  return null;
};

const edit = async (id: number, patch: Partial<Contact>): Promise<Contact | null> => {
  const res = await ajaxActions.patch(`${BASE_URL}/contacts/${id}`, patch);
  if (res.ok) {
    return res.json();
  }
  ToastS.generalError();
  return null;
};

const saveOrUpdate = async (contact: Partial<Contact>) => {
  const res = await ajaxActions.exchange('POST', `${BASE_URL}/contacts${contact.id ? `/${contact.id}` : ''}`, contact);
  if (res.ok) {
    return res.json();
  }
  if (res.status === 409) {
    ToastS.error('email-confict', 'Der Kontakt mit der angegebenen E-Mail-Adresse existiert bereits.');
    return null;
  }
  ToastS.generalError();
  return null;
};

const fetchRelatedContacts = async (contactId: number): Promise<ContactRelation[]> => {
  const res = await ajaxActions.get(`${BASE_URL}/relatedContacts/${contactId}`);
  if (res.ok) {
    return res.json();
  }
  ToastS.generalError();
  return [];
};

const addRelatedContact = async (contactId: number, relatedContactId: number): Promise<ContactRelation | null> => {
  const res = await ajaxActions.put(`${BASE_URL}/contactRelations/${contactId}/${relatedContactId}`);
  if (res.ok) {
    return res.json();
  }
  ToastS.generalError();
  return null;
};

const patchContactRelation = async (contactRelationId: number, patch: Partial<ContactRelation>) => {
  const res = await ajaxActions.patch(`${BASE_URL}/contactRelations/${contactRelationId}`, patch);
  if (res.ok) {
    return res.json();
  }
  ToastS.generalError();
  return null;
};

const deleteContactRelation = async (contactRelationId: number): Promise<boolean> => {
  const res = await ajaxActions.del(`${BASE_URL}/contactRelations/${contactRelationId}`);
  if (res.ok) {
    return true;
  }
  ToastS.generalError();
  return false;
};

const fetchDescriptions = async (contactIds: number[], abortSignal?: AbortSignal): Promise<ContactDescription> => {
  const res = await ajaxActions.get(`${BASE_URL}/contacts/description?contactIds=${[contactIds]}`, {
    signal: abortSignal,
  });
  if (res.ok) {
    return res.json();
  }
  ToastS.generalError();
  return [];
};

const getPartnerFormValues = (partner: Contact | undefined) => {
  if (!partner) {
    return {
      partnerSalutation: '',
      partnerFirstName: '',
      partnerLastName: '',
      partnerEmail: '',
      partnerPhoneNumber: '',
      partnerPhoneNumberAlt: '',
      partnerContactType: 'PRIVATE',
    };
  }
  const {
    salutation: partnerSalutation,
    firstName: partnerFirstName,
    lastName: partnerLastName,
    email: partnerEmail,
    phoneNumber: partnerPhoneNumber,
    phoneNumberAlt: partnerPhoneNumberAlt,
  } = partner;

  return {
    partnerSalutation: partnerSalutation ?? '',
    partnerFirstName,
    partnerLastName,
    partnerEmail,
    partnerPhoneNumber,
    partnerPhoneNumberAlt,
    partnerContactType: 'PRIVATE',
  };
};

const extractPartnerFormValues = (values: any, partner?: Contact): Partial<Contact> => {
  const partnerFields: Partial<Contact> = {
    salutation: values.partnerSalutation,
    firstName: values.partnerFirstName,
    lastName: values.partnerLastName,
    email: values.partnerEmail !== null && values.partnerEmail.trim() === '' ? null : values.partnerEmail,
    phoneNumber: values.partnerPhoneNumber,
    phoneNumberAlt: values.partnerPhoneNumberAlt,
    contactType: values.partnerContactType as ContactType,
  };
  if (partner) {
    partnerFields.id = partner.id;
  }
  return partnerFields;
};

const getEventContacts = async (eventId: string): Promise<Contact[]> => {
  const participants = await EventS.getEventParticipations(eventId);
  const contactIds = participants.map((p) => p.contactId);
  return await fetchContactsById(contactIds);
};

const fetchContactsById = async (contactIds: number[]): Promise<Contact[]> => {
  const res = await ajaxActions.post(`${BASE_URL}/contacts/list`, contactIds);
  if (res.ok) {
    return res.json();
  }
  return [];
};

const importContactsCSV = async (csvFile: File) => {
  const res = await ajaxActions.uploadPutFile(`${BASE_URL}/import/contacts`, csvFile);
  if (res.ok) {
    ToastS.success('import-event', 'Import finished');
  } else {
    ToastS.generalError();
  }
};

export const ContactS = {
  ContactSkeleton,
  fetchAll,
  fetchAllWithFilter,
  fetchById,
  edit,
  saveOrUpdate,
  fetchRelatedContacts,
  addRelatedContact,
  patchContactRelation,
  deleteContactRelation,
  fetchDescriptions,
  getPartnerFormValues,
  extractPartnerFormValues,
  getEventContacts,
  fetchContactsById,
  importContactsCSV,
};
