// eslint-disable-next-line no-redeclare, @typescript-eslint/no-redeclare
import { Loader, Select, Text } from '@mantine/core';
import { FC } from 'react';
import { IconBrandGmail, IconChevronDown } from '@tabler/icons';
import { useNavigate } from 'react-router-dom';
import { MailOutBoundConfigDTO } from '../../../../Types/MailT';
import { useGoogleAccounts } from '../../../../Service/hooks/useGoogleAccounts';
import { DataS } from '../../../../Service/DataS';

interface Props {
  connectedAccountId: string | null;
  handleChange: (patch: Partial<MailOutBoundConfigDTO>) => void;
}

const GoogleGmailAccounts: FC<Props> = ({ connectedAccountId, handleChange }) => {
  const navigate = useNavigate();
  const { loading, googleAccounts } = useGoogleAccounts();
  const candidates = googleAccounts.filter((acc) => acc.hasSendMailScope);
  const noCandidate = candidates.length === 0;

  if (!loading && noCandidate) {
    return (
      <Text italic mt="md">
        Du hast noch kein Google-Konto verbunden. Bitte füge auf{' '}
        <Text span color="blue" variant="link" sx={{ cursor: 'pointer' }} onClick={() => navigate('/settings/google')}>
          dieser Seite
        </Text>{' '}
        Dein Konto, mit der Berechtigung zum Senden von E-Mails, hinzu.
      </Text>
    );
  }

  return (
    <Select
      required
      mb="sm"
      icon={loading ? <Loader size="xs" /> : <IconBrandGmail size={16} />}
      label="Konto"
      placeholder="Konto auswählen"
      onChange={(accountId) => handleChange({ externalAccountId: accountId })}
      value={connectedAccountId}
      data={DataS.mapGoogleAccounts(candidates)}
      styles={{ rightSection: { pointerEvents: 'none' } }}
      rightSection={<IconChevronDown size={16} />}
      rightSectionWidth={25}
    />
  );
};

export default GoogleGmailAccounts;
